/*
  Ids used on this file:
  /app/assets/images/sprite/icons.svg
*/
export const ICONS_4 = [
  "battery",
  "wifi",
  "antenna-connection",
  "16-workflows-v2-contact-tagged",
  "16-workflows-v2-post-published",
  "16-workflows-v2-post-comment-created",
  "16-workflows-v2-community-member-completed-course",
  "16-workflows-v2-community-member-completed-lesson",
  "16-workflows-v2-entry-rule",
  "16-workflows-v2-community-member-rsvps-to-recurring-event",
  "16-workflows-v2-community-member-leaves-space",
  "16-workflows-v2-contact-untagged",
  "16-workflows-v2-post-liked",
  "16-workflows-v2-community-member-completed-section",
  "16-workflows-v2-community-member-started-paywall-trial",
  "16-workflows-v2-community-member-ended-trial-without-subscription",
  "16-workflows-v2-event-published",
  "16-workflows-v2-community-member-attended-live-event",
  "16-workflows-v2-community-member-email-changed",
  "16-workflows-v2-post-comment-created",
  "16-workflows-v2-community-member-purchases-paywall",
  "16-workflows-v2-community-member-subscribed-to-paywall",
  "16-workflows-v2-community-member-unsubscribed-from-paywall",
  "16-workflows-v2-send-email",
  "16-workflows-v2-send-notification-to-member",
  "16-workflows-v2-send-dm",
  "16-workflows-v2-remove-community-member-from-community",
  "16-workflows-v2-enable-messaging-on-member",
  "16-workflows-v2-disable-messaging-on-member",
  "16-workflows-v2-unsubscribe",
  "16-workflows-v2-add-tag-to-community-member",
  "16-workflows-v2-remove-tag-from-community-member",
  "16-workflows-v2-add-community-member-to-space",
  "16-workflows-v2-remove-community-member-from-space",
  "16-workflows-v2-add-community-member-to-space-group",
  "16-workflows-v2-remove-community-member-from-space-group",
  "16-workflows-v2-cancel-community-member-paywall-subscription",
  "16-workflows-v2-subscribe-community-member-to-paywall-trial",
  "16-workflows-v2-rsvp-member-to-event",
  "16-workflows-v2-time-delay",
  "16-workflows-v2-community-member-passed-quiz",
  "16-workflows-v2-community-member-failed-quiz",
  "16-workflows-v2-community-member-submitted-quiz",
  "16-workflows-v2-received-form-submission",
  "16-workflows-v2-award-points",
  "16-workflows-v2-send-to-webhook",
  "16-workflows-v2-graph-arrow",
  "20-green-circle",
  "20-blue-circle",
  "48-ai-bot",
  "16-inbox-unread",
  "120-ai-bot",
  "16-ai",
  "16-group",
  "14-ai-pencil",
  "16-ai-sources",
  "32-ai-bot",
  "20-ai-bot-gradient",
  "20-bot-customize",
  "20-bot-behavior",
  "16-workflows-v2-add-audience",
  "20-bot-content",
  "20-book",
  "20-note",
  "24-ai-list-sparkle",
  "24-note-text",
  "20-normal-behavior-type",
  "20-friendly-behavior-type",
  "20-short-and-sweet-behavior-type",
  "20-descriptive-behavior-type",
  "16-workflows-v2-leveled-down",
  "16-audience",
  "20-audience",
  "audience-dots",
  "16-sort-icon",
  "16-chevron-triangle-down-small",
  "20-reload",
] as const;
